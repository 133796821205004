<template>
  <div class="top">
    <div class="cover">
      <img
        src="@/assets/Robecity-deshi-image001.jpg"
        class="pc-only"
        alt="トップ画像PC"
      />
      <img
        src="@/assets/Robecity-deshi-image-SP001.jpg"
        class="sp-only"
        alt="トップ画像SP"
      />
    </div>

   <img
        src="@/assets/Robecity-deshi-image001.png"
        alt="トップテキストSP"
        class="box smooth pc-only"
      />
   <img
        src="@/assets/Robecity-deshi-image-SP001.png"
        alt="トップテキストSP"
        class="box smooth sp-only"
      />

  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "top",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  position: relative;
}
/* CSSアニメーションの指定 */
/* 滑らかに変形して出現 */
.smooth {
  animation-name: smoothAnime;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  transform-origin: left;
  opacity: 0;
  position: absolute;
  top: 32%;
  right: 0.05%;
  @media screen and (max-width: 767px){
    right: 0%;
    top:33%
    ;

  }
}

@keyframes smoothAnime {
  from {
    transform: translate3d(0, 100%, 0) skewY(12deg);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0) skewY(0);
    opacity: 1;
  }
}
/*========= レイアウトのためのCSS ===============*/


.box {
  width: 35%;
  @media screen and(max-width: 767px){
    width: 70%;
  }
}
</style>
