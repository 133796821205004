<template>
  <div class="cotacnt-form">
    <div class="cover">
      <div class="title">
        <!-- <div v-if="pageType === 0"></div> -->
      </div>

      <div class="form" v-if="pageType === 0 || pageType === 1">
        <div class="input-cover">
          <p class="sub">お名前</p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.name"
              type="text"
              class="row1"
              placeholder="例）山田 花子"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.name }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">お問い合わせ区分</p>
          <div v-if="pageType === 0" class="row-cover radio-bt">
            <label class="sp"
              ><input
                v-model="form.type"
                type="radio"
                :value="0"
              />弟子の応募</label
            >
            <label class="sp"
              ><input v-model="form.type" type="radio" :value="9" />質問</label
            >
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            <span v-show="form.type === 0">弟子の応募</span>
            <span v-show="form.type === 9">質問</span>
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">連絡先メールアドレス</p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.mail"
              type="email"
              class="row1"
              placeholder="例）sample@gmail.com"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.mail }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">電話番号</p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.tel"
              type="tel"
              class="row1"
              placeholder="例）090-1234-5678"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.tel }}
          </div>
        </div>
        <div class="input-cover">
          <p class="sub">年齢</p>
          <div v-if="pageType === 0">
            <input
              v-model="form.age"
              type="age"
              class="row1 age"
              placeholder="例）28"
            />
            <span class="sai">歳</span>
          </div>
          <div v-else-if="pageType === 1" class="confirmation age">
            {{ form.age }}<span class="sai">歳</span>
          </div>
        </div>
        <div class="input-cover">
          <p class="sub">居住地</p>
          <div v-if="pageType === 0" class="row-cover">
            <input
              v-model="form.area"
              type="tel"
              class="row1"
              placeholder="例）岐阜県岐阜市"
            />
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.area }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">現職・過去の職業の概要</p>
          <div v-if="pageType === 0">
            <textarea
              v-model="form.profession"
              placeholder="こちらに現職・過去の職業について入力をしてください。"
            ></textarea>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.profession }}
          </div>
        </div>

        <div class="input-cover">
          <p class="sub">自己PR、または、質問内容</p>
          <div v-if="pageType === 0">
            <textarea
              v-model="form.description"
              placeholder="こちらに自己PR・または、質問について入力をしてください。"
            ></textarea>
          </div>
          <div v-else-if="pageType === 1" class="confirmation">
            {{ form.description }}
          </div>
        </div>
      </div>
      <div v-if="pageType === 0">
        <label class="ECM_CheckboxInput">
          <input
            type="checkbox"
            name="check"
            id="check"
            v-model="checked"
            class="ECM_CheckboxInput-Input"
          /><span class="ECM_CheckboxInput-DummyInput"></span
          ><span class="ECM_CheckboxInput-LabelText"></span
          >当ページに記載された内容をすべて読み、理解した上で、弟子の応募、または質問を送付します。</label
        >
      </div>

      <div @click="confirm" class="button" v-if="pageType === 0">
        <div :class="checked === true ? 'click-ok' : 'click-ng'">
          <p>確認</p>
        </div>
      </div>

      <div class="soushin" v-if="pageType === 1">
        <div @click="back" class="button"><p>修正</p></div>
        <div @click="send" class="button"><p>送信</p></div>
        <!-- @click="send"を本番は復活させること -->
      </div>
      <div v-else-if="pageType === 2" class="complete">
        <h3 style="margin-bottom: 50px; text-align: center">
          お問い合わせを承りました
        </h3>
        <p>
          お問い合わせ内容を、ご入力のメールアドレスに送信しました。<br />20分経過しても届かない場合は、迷惑メールボックスの中をご確認ください。
        </p>
        <!-- <router-link to="/"> -->
        <div class="button2" @click="top">トップに戻る</div>
        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CotacntForm",
  components: {},
  props: {
    type: Number,
  },
  data() {
    return {
      pageType: 0,
      checked: false,
      form: {
        name: "",
        type: "",
        mail: "",
        age: "",
        tel: "",
        area: "",
        profession: "",
        description: "",
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  created() {},
  methods: {
    confirm() {
      if (this.checked === true) {
        // ok.
      } else {
        alert("チェック入れて確認ボタンを押してください。");
        return;
      }
      // alert("aiueo!");
      console.log(this.form);

      let validation_ok = true;
      let validation_message = "";
      console.log(validation_ok);
      console.log(validation_message);

      //お名前チェック
      if (this.form.name !== "") {
        console.log("お名前 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お名前」";
      }
      //お問い合わせ区別チェック
      if (this.form.type !== "") {
        console.log("お問い合わせ区分 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「お問い合わせ区分」";
      }
      //メールアドレスチェック
      if (this.form.mail !== "") {
        var mailAddress = this.form.mail;
        var hotResult = mailAddress.indexOf("hotmail.co.jp");
        var outResult = mailAddress.indexOf("outlook.jp");

        // hotmail,outlookチェック
        if (hotResult === -1 && outResult === -1) {
          //含まれていない
        } else {
          validation_ok = false;
          alert(`hotmail. outlookは使えません`);
          return;
        }

        console.log("メールアドレス ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「メールアドレス」";
      }
      //電話番号チェック
      if (this.form.tel !== "") {
        console.log("電話番号 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「電話番号」";
      }
      //年齢チェック
      if (this.form.age !== "") {
        console.log("年齢 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「年齢」";
      }
      //電話番号チェック
      if (this.form.tel !== "") {
        console.log("電話番号 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「電話番号」";
      }
      //居住地チェック
      if (this.form.area !== "") {
        console.log("居住地 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「居住地」";
      }
      //職業概要チェック
      if (this.form.profession !== "") {
        console.log("職業の概要 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「職業の概要」";
      }

      //自己PR・質問
      if (this.form.description !== "") {
        console.log("自己PR・質問 ok");
      } else {
        validation_ok = false;
        validation_message = validation_message + "「自己PR・質問」";
      }

      //結果発表
      if (validation_ok === true) {
        this.pageType = 1;
        return;
      } else {
        alert(`${validation_message}を入力してください`);
        return;
      }
    },
    send() {
      let params = new URLSearchParams();
      params.append("name", this.form.name);
      params.append("type", this.form.type);
      params.append("mail", this.form.mail);
      params.append("age", this.form.age);
      params.append("tel", this.form.tel);
      params.append("area", this.form.area);
      params.append("profession", this.form.profession);
      params.append("description", this.form.description);
      this.$axios
        .post(`${this.rootUrl}/api/send.php`, params)
        .then((response) => {
          console.log(response);
          alert(response.data.message);
          if (response.data.status === 200) {
            // console.log('ok')
            this.pageType = 2;
            return;
          } else if (
            response.data.status === 400 ||
            response.data.status === 500
          ) {
            alert(response.data.message);
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      // alert("本当に戻りますか？");
      this.pageType = 0;
    },
    top() {
      this.$scrollTo("#app", 500, { easing: "ease" });
      this.pageType = 0;
      this.form = "";
      this.checked = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cotacnt-form {
  background-color: white;
}
.cover {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  @media screen and (max-width: 767px) {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .title {
    padding-bottom: 30px;
    padding-left: 0;

    @media screen and (max-width: 767px) {
      padding-bottom: 5px;
    }

    h3 {
      padding-top: 15px;
      @media screen and (max-width: 767px) {
        padding-top: 30px;
      }
    }
  }

  .form {
    margin-bottom: 50px;
    margin-top: 50px;

    .input-cover {
      text-align: left;
      margin-bottom: 30px;
      .sub {
        font-size: 18px;
        margin-bottom: 5px;
        color: var(--sub3);
      }
      input,
      textarea {
        border: none;
      }
      .row-cover,
      .confirmation {
        border: 1px solid #a7a5a4;
      }
      .radio-bt {
        padding: 20px 10px;
        font-size: 18px;
      }
      .select {
        display: inline-block;
        position: relative;
        border: 1px solid #eee;
        vertical-align: middle;
      }
      .select::before {
        position: absolute;
        top: 22px;
        right: 16px;
        width: 0;
        height: 0;
        border-width: 15px 10px 0 10px;
        border-style: solid;
        border-color: var(--main) transparent transparent transparent;
        content: "";
        pointer-events: none;
      }
      select {
        appearance: none;
        min-width: 300px;
        padding: 20px 15px;
        padding-right: 1em;
        border: 1px solid #a7a5a4;
        outline: 0;
        background: #fff;
        background-image: none;
        box-shadow: none;
        text-indent: 0.01px;
        text-overflow: ellipsis; // 文字の省略
        cursor: pointer;
        font-size: 18px;
        @media screen and (max-width: 767px) {
          min-width: 250px;
        }
      }

      .row1,
      textarea {
        width: 100%;
        padding: 20px 10px;
        font-size: 20px;
        resize: auto;
        cursor: text;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        column-count: initial !important;
        word-break: break-all;
      }

      .age {
        border: 1px solid #a7a5a4;
        width: 150px;
      }
      .sai {
        margin-left: 10px;
        font-size: 18px;
      }

      textarea {
        border: 1px solid #a7a5a4;
        height: 300px;
        text-rendering: auto;

        @media screen and (max-width: 767px) {
          margin: 0;
        }
      }

      .sp {
        &:nth-child(n + 2) {
          margin-left: 20px;
          @media screen and (max-width: 767px) {
            margin-left: 0;
          }
        }

        @media screen and (max-width: 767px) {
          display: block;
        }
      }
    }
  }
  .button,
  .button2 {
    border: 1px solid var(--sub);
    color: var(--sub);
    // padding: 20px;
    width: 200px;
    text-align: center;
    margin: 50px auto;
    cursor: pointer;
    transition: 0.8s;
    p {
      font-size: 20px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    @media screen and (max-width: 767px) {
      width: 200px;
      padding: 20px 0px;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
    }

    &:hover {
      color: white !important;
      background-color: var(--sub);
      transition: 0.8s;
    }
  }

  .soushin {
    display: flex;
    justify-content: space-between;
    .button,
    .button2 {
      width: 150px;
      @media screen and (max-width: 767px) {
      }
      width: 40%;
      font-size: 18px;
    }
  }

  .complete {
    text-align: left;
    h2 {
      @media screen and (max-width: 767px) {
        font-size: 25px;
      }
    }
    a {
      text-decoration: none;
      color: black;

      .button2 {
        width: 170px;
        margin-top: 70px;
        margin-bottom: 50px;
      }
    }
    p {
      text-align: center;
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
  }
}

.confirmation {
  padding: 20px;
}

.click-on {
  cursor: pointer;
}
.click-ng {
  cursor: not-allowed;
}
.desc {
  color: var(--sub3);
}
.ECM_CheckboxInput {
  padding: 12px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-size: 16px;
  @media screen and (max-width: 767px){
    // flex-direction: column;
    align-items: flex-start;
    padding: 5px 0;
    
    }
}
.ECM_CheckboxInput-Input {
  margin: 0;
  width: 0;
  opacity: 0;
}
.ECM_CheckboxInput:hover > .ECM_CheckboxInput-DummyInput {
  transform: scale(1.1);
}
.ECM_CheckboxInput-Input:focus + .ECM_CheckboxInput-DummyInput {
  transform: scale(1.1);
}
.ECM_CheckboxInput-Input:checked + .ECM_CheckboxInput-DummyInput {
  background: rgba(112, 55, 144, 1);
  border: none;
}
.ECM_CheckboxInput-Input:checked + .ECM_CheckboxInput-DummyInput::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 35%;
  height: 4px;
  border-radius: 2px;
  transform: translate(-6px, 5px) rotateZ(-135deg);
  transform-origin: 2px 2px;
  background: #ffffff;
}
.ECM_CheckboxInput-Input:checked + .ECM_CheckboxInput-DummyInput::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 4px;
  border-radius: 2px;
  transform: translate(-6px, 5px) rotateZ(-45deg);
  transform-origin: 2px 2px;
  background: #ffffff;
}
.ECM_CheckboxInput-DummyInput {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 32px;
  height: 32px;
  border: solid 2px transparent;
  // background: rgba(0, 0, 0, .15);
  border: 2px solid var(--main);
  // border-radius: 50%;
  transition: all 0.15s linear;
    @media screen and (max-width: 767px){
  width: 74px;
  margin-top: 5px;
  }
}
.ECM_CheckboxInput-LabelText {
  margin-left: 12px;
  display: block;
  font-size: 18px;
  font-weight: bold;
}
</style>
