<template>
  <div class="about-robecity">
    <div class="whiteback space-top-bottom-set">
      <div class="wrap-center">
        <div class="title">
          <div class="cover">
            <h2>Recruitment<br />details</h2>
            <p class="mincyo sub">募集要項の詳細</p>
            <h3 class="mincyo sub-vioret space-top-50">
              弟子としての活動の形態
            </h3>
          </div>
          <img src="@/assets/Robecity-deshi-006.jpg" alt="手掛けた物件4" />
        </div>
        <p class="detail">
          外注・業務委託として一緒に活動をしていただきます。経営者である安田自身の働き方もそうですし、将来的には各エリアの支店のトップ、または、FCの代表、つまり経営者になることが前提のため、「決まった時間」という概念なく活動できることが条件となります。「平日の9時～18時に活動して、夜や土日はしっかり休み」という活動の仕方を希望される方は求めていません。
        </p>
        <h3 class="mincyo sub-vioret space-top-50">職種・業務内容</h3>
        <p class="detail">
          安田が日々行っている建築デザイン業務と、それに付随する業務となります。打ち合わせの代行、安田の雑務、安田の秘書的な活動もあります。基本的に、どんなこともすべて自発的にこなしていただくことができる方を求めています。
        </p>
        <h3 class="mincyo sub-vioret space-top-50">応募条件</h3>
        <ul>
          <li>
            将来的にRobecityの各エリアのトップ、または、FCの代表になること
          </li>
          <li>男性（ナヨナヨした人は不可）</li>
          <li>35歳まで</li>
          <li>自動車の運転ができること（ペーパードライバー不可）</li>
          <li>
            建築系の社会人経験が最低2年以上あること（新卒者・異業種からの応募不可）
          </li>
          <li>建築業界の言語や用語で会話ができること</li>
          <li>CAD（JWまたはVectorworks）の操作経験者</li>
          <li>Excel・Wordの使用ができること</li>
        </ul>
        <p>
          ※当ページに記載された内容について、理解していただいていることが前提となります。
        </p>
        <h3 class="mincyo sub-vioret space-top-50">報酬</h3>
        <p class="detail">
          月15万円(税別)です。Robecityと安田からの学びと経験、将来の独立が報酬だという位置づけで一緒に活動していただける方を弟子として求めています。「弟子としての修行の期間」だと考えられる方を求めています。むしろ、「お金をもらいながら、学べて、経験も積めて、独立も支援してもらる」ということに対して価値を理解いただける方が理想です。
        </p>
        <h3 class="mincyo sub-vioret space-top-50">フィードバック</h3>
        <p class="detail">
          将来、独立して、一緒にRobecityの全国展開ができる人を募っています。そのため、実際に一緒に活動してみて、見込みがない、将来の独立が難しいと判断した場合は、弟子としての仕事・教育を打ち切ります。安田および管理者から3ヶ月周期で率直にフィードバックをします。
        </p>
        <h3 class="mincyo sub-vioret space-top-50">募集人数</h3>
        <p class="detail">1名です。この1名が決まったら、次の応募は未定です。</p>
        <h3 class="mincyo sub-vioret space-top-50">重要事項</h3>
        <p class="detail">
          弟子として安田と一緒に活動することになった場合、将来のRobecityの独立など前提条件に対する契約を締結していただきます。弟子として教育を受けながら、Robecityとして独立しないなど、こちらが提示する条件と異なることがある場合は、当契約内容に応じて、違約金などの対応をさせていただくことになります。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "about-robecity",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.whiteback {
  background-color: white;
  margin-left: calc(calc(100% - 1100px) * 0.5);
  padding-right: calc(calc(100% - 1100px) * 0.5);
  @media screen and (max-width: 1100px) {
    margin: 0 auto;
    padding-right: 0;
  }
  .wrap-center {
    padding-left: 246px;
    @media screen and (max-width: 1100px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    .title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      img {
        width: 30%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        display: block;
        img {
          width: 100%;
        }
      }
    }
    .sub-vioret {
      padding-bottom: 20px;
    }
    .detail {
      color: var(--main);
    }
    ul {
      padding-bottom: 15px;
      li {
        list-style: disc;
        margin-left: 1.5em;
        text-indent: -1.5em;
        padding-left: 1.5em;
          @media screen and (max-width: 767px) {
           margin-left: 1.5em;
        text-indent: 0em;
        padding-left: 0; 
  }

      }
    }
  }
}
</style>
