<template>
  <div class="about-recruit">
    <h2>Vision</h2>
    <p class="mincyo sub">Robecityのビジョン</p>
    <div class="cover">
      <div class="text-cover">
        <h3 class="sub-vioret mincyo">日本に<br />"Robecity"を作る</h3>
        <div class="text">
          <p>
            当建築デザイン事務所の名前である「Robecity（ローブシティ）」には、代表の安田が込めた思いがあります。「Robe」は「羽織物」を意味し、建築は「人を一番外側で包んでいるもの」。身体に密着したオートクチュールのような住宅をテーマに、そんな建築が集まる街「City」を創りたいという思いから、「Robecity（ローブシティ）」という名前にしました。
          </p>
          <p>
            日本をRobecityがデザインした物件でいっぱいにしたい。しかし、現在のRobecityでは、
          </p>
          <ul>
            <li>新規の問い合わせの多くをキャパオーバーでお断りしている</li>
            <li>
              Robecityの本社がある東海近郊エリアを中心にしか十分な業務対応ができていない
            </li>
            <li>
              同じビジョンを持ち、高いレベルで一緒に仕事ができる仲間が必要
            </li>
          </ul>
          <p>という状況で、まだまだ道は遠い状況です。</p>
          <p>
            このビジョンを達成するために、将来的にRobecityの全国展開を、支店のトップ、または、FCオーナーとして務めていただくことを前提とした「安田の弟子」を募集しています。募集人数は1名です。
          </p>
        </div>
      </div>
      <img src="@/assets/Robecity-deshi-001.png" alt="ビジョンイメージ" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "about-recruit",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cover {
  display: flex;
  @media screen and (min-width:768px) and ( max-width:1024px){
    align-items: flex-start;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .text-cover {
    width: 60;
    .text{
      ul {
        padding-bottom: 15px;
        li {
          list-style: disc;
          margin-left: 1.5em;
          text-indent: -1.5em;
          padding-left: 1.5em;
          @media screen and (max-width: 767px) {
            text-indent: 0;
            padding-left: 0em;

          }
        }
      }
    }
    p {
      padding-bottom: 15px;
    }
  }
  img {
    width: 40%;
    margin-right: 164px;
    @media screen and (min-width:768px) and ( max-width:1024px){
    margin-right: 0;
    margin-left: 10px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }
  }
}
</style>
