<template>
  <div class="layout-footer">
    <!-- <div class="tokuteisho" v-show="path !== `/tokuteisho/`">
      <router-link to="/tokuteisho/">特定商取引法に基づく表示</router-link>
    </div> -->
    <div class="footer" :class="{ 'footer-sp-bottom': path !== `/contact/` }">
      <div class="fotter-inner">
        <div class="logo">
          <img
            alt="ローブシティーロゴ"
            src="@/assets/Robecity-deshi-logo002.png"
          />
        </div>
        <div class="copyright">
          <p>Copyright © RobeCity All Rights Reserved.</p>
        </div>
      </div>

      <div class="sp-contact-overlay">
        <div class="contact-item">
          <router-link to="/" v-scroll-to="`#contact`" class="mail-box">
            <span>弟子の応募・お問い合わせ</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LayoutFooter",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: var(--sub2);
}
.st1 {
  fill: none;
}
.tokuteisho {
  text-align: center;
  margin-bottom: 5px;
  a {
    text-decoration: none;
    color: black;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.footer-sp-bottom {
  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
}
.footer {
  .fotter-inner {
    background-color: var(--main);
    // height: 150px;
    .logo {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-left: 2%;
      img {
        width: 200px;
        margin: 80px 0 0;
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          margin: 50px 0 20px;
          width: 120px;
        }
        @media screen and (max-width: 767px) {
          width: 70px;
          margin: 50px 0 0;
        }
      }
    }
  }

  .copyright {
    font-size: 13px;
    color: white;
    text-align: right;
    padding-bottom: 20px;
    margin-right: 100px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-top: 15px;
      text-align: center;
    }
  }
  .sp-contact-overlay {
    display: none;
    @media screen and (max-width: 767px) {
      display: initial;
      position: fixed;
      bottom: 0;
      width: 100%;
      // height: 65px;
      background-color: rgba(255, 255, 255, 0.9);
      z-index: 999;
      .contact-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      a {
        text-decoration: none;
      }
      .tel-box,
      .line-box,
      .mail-box {
        display: flex;
        position: relative;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px;
        margin: 5px 20px;
        border: 1px solid gray;
        span {
          color: var(--main);
          margin-top: 3px;
          font-size: 13px;
        }
        img {
          width: 28px;
          margin-right: 10px;
        }
      }
      
    }
  }
}

</style>
