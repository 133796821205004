import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'

import Home from '../views/Home.vue'

// Ctrl
import Ctrl from '../views/Ctrl.vue'
import CtrlHome from '../views/Ctrl/Home.vue'
import CtrlFollowers from '../views/Ctrl/Followers.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Robecity安田の弟子募集',
      description: '建築デザイン事務所Robecitでは、代表デザイナーの安田の弟子を募集しています。2年間の修行の後、Robecityの全国の支店のトップ、または、FCに代表として独立していただきます。'
    }
  },
  {
    path: '/ctrl/:password',
    name: 'ctrl',
    component: Ctrl,
    beforeEnter(to, from, next) {
      if (to.params.password === 'GXPkqumPtbwMVcKi7ewDBXNfn8Mar5N8') {
        next();
      } else {
        next('/');
      }
    },
    children: [
      {
        path: '',
        name: 'ctrl-home',
        component: CtrlHome,
        meta: {
          title: '',
          description: ''
        }
      },
      {
        path: 'followers',
        name: 'ctrl-followers',
        component: CtrlFollowers,
        meta: {
          title: '',
          description: ''
        }
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  let documentHead = document.getElementsByTagName('head');
  let gfjScript = document.createElement('script');
  gfjScript.type = 'application/ld+json';
  gfjScript.innerHTML = to.meta.gfjScriptContent;
  documentHead[0].appendChild(gfjScript);
  //↑gfj用
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  next();

  // if (to.path.slice(-1) === "/") {
  //   next(to.path.slice(0, -1));
  // } else {
  //   next();
  // }
});

export default router
