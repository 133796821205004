<template>
  <div class="slider-box">
    <div class="slider">
      <swiper :options="swiperOption" :style="`width:${width}vw;height:${height}%;`">
        <swiper-slide v-for="image in images" :key="image.id"
          >
        <img
        :src="require(`@/assets/slider/${image.imgpath}`)"
        :alt="image.name"
      />
        </swiper-slide>
        <!-- <div class="slide-op" v-show="button"> -->
         <div v-show="button" class="swiper-pagination" slot="pagination"></div>
            <div v-show="button" class="swiper-button-prev" slot="button-prev"></div>
            <div v-show="button" class="swiper-button-next" slot="button-next"></div>
   
    <!-- </div> -->
      </swiper>
        </div>
    <!-- <div class="test">
      <p v-for="fruit in fruits" :key="fruit.id">
        {{ fruit.id }}{{ fruit.name }}{{ fruit.price }}
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "slider-box",
  components: {},
  props: {
    images: Array,
    swiperOption: Object,
    height: Number,
    width: Number,
    button: Boolean,
  },
  // props: {
  //   path: String,
  //   type: Number,
  // },
  data() {
    return {
      //       swiperOption: {
      //   speed: 7000,
      //   effect: "",
      //   spaceBetween: 30,
      //     slidesPerView: 3,
      //     // loop: true,
      //   // autowidth: true,
      //   autoplay: {
      //     delay: 0,
      //     disableOnInteraction: false,
      //   },
      // },
      //   pagination: { //ページネーション設定
      //     el: '.swiper-pagination',
      //     clickable: true
      //   },
      //   navigation: { //ナビゲーション設定
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev'
      //   }
      
    };
  },
  computed: {
    rootUrl() {
      return this.$store.getters.rootUrl;
    },
  },
  methods: {

  },
  plugins: [{ src: "~/plugins/swiper", mode: "client" }],
};
</script>

<style lang="scss" scoped>

.swiper-container{
  // width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  background-color: var(--black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  
  
  // border-left:1px solid #fff;
}
.swiper-wrapper {
  transition-timing-function: linear !important;

}
</style>
