<template>
  <div class="followers">
    <h2>フォロワー</h2>
    <Editor />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Editor from "@/components/Ctrl/Followers/Editor.vue";

export default {
  name: "Followers",
  components: {
    Editor,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
</style>