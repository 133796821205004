<template>
  <div class="home">
    <div class="back-color1">
      <div class="top wrap-right">
        <HomeTop />
      </div>
      <div class="space-top-bottom-set wrap-center">
        <HomeAboutRecruit />
      </div>
    </div>

    <div class="back-color2">
      <div class="wrap-right space-top-bottom-set" >
        <HomeVision />
      </div>
    </div>

    <div class="back-color1">
        <HomeAboutRobecity />
  </div>

    <div class="back-color1">
        <HomeMeritEtc />
  </div>

    <div class="back-color1">
        <HomeRecruitmentDetails />
  </div>

    <div class="back-color1">
        <HomeQA />
  </div>

    <div class="back-color2">
        <HomeFlow />
  </div>
    <div class="back-color1">
        <HomeContact />
  </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeTop from "@/components/Home/Top.vue";
import HomeAboutRecruit from "@/components/Home/AboutRecruit.vue";
import HomeVision from "@/components/Home/Vision.vue";
import HomeAboutRobecity from "@/components/Home/AboutRobecity.vue";
import HomeMeritEtc from "@/components/Home/MeritEtc.vue";
import HomeRecruitmentDetails from "@/components/Home/RecruitmentDetails.vue";
import HomeQA from "@/components/Home/QA.vue";
import HomeFlow from "@/components/Home/Flow.vue";
import HomeContact from "@/components/Home/Contact.vue";

export default {
  name: "Home",
  components: {
    HomeTop,
    HomeAboutRecruit,
    HomeVision,
    HomeAboutRobecity,
    HomeMeritEtc,
    HomeRecruitmentDetails,
    HomeQA,
    HomeFlow,
    HomeContact,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.back-color1 {
  background-color: var(--sub2);
}
.back-color2 {
  background-color: rgb(227, 227, 231);
}
</style>