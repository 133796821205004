<template>
  <div class="ctrl">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Ctrl",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
/deep/h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 30px auto 10px;
}
</style>