import { render, staticRenderFns } from "./AboutRecruit.vue?vue&type=template&id=2832a24b&scoped=true"
import script from "./AboutRecruit.vue?vue&type=script&lang=js"
export * from "./AboutRecruit.vue?vue&type=script&lang=js"
import style0 from "./AboutRecruit.vue?vue&type=style&index=0&id=2832a24b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2832a24b",
  null
  
)

export default component.exports