<template>
  <div class="about-robecity">
    <div class="back space-top-bottom-set">
      <div class="inner-wrap">
        <div class="item-box">
          <div class="left">
            <h2>Merit</h2>
            <p class="mincyo sub">安田の弟子になる<br class="tb-only">メリット</p>
            <img src="@/assets/Robecity-deshi-001.jpg" alt="手掛けた物件1" />
          </div>
          <div class="right">
            <p>
              生きる教科書として、Robecity代表の安田の仕事を、すべて、日々、真横で一緒の目線で見ること、体感すること、学ぶことができます。大きな建築事務所ではないため、建築デザイナーとして独立するために必要なすべてを学ぶことができます。そして、最短2年間の教育を経て、日本の各エリアのRobecityの支店の代表、または、FCの代表として独立することができます。まさに最強の建築デザイン学校です。
            </p>
            <ul class="down-list">
              <li>安田の横について、安田の仕事現場・仕事の仕方を体感できる</li>
              <li>
                設計、デザイン、営業、クロージングの方法、打ち合わせなどすべてを学べる、体験できる
              </li>
              <li>
                安田のアンテナに刺さって視察する建築物に一緒に同行・宿泊して見学できる
              </li>
              <li>
                最短2年間の弟子を経て、支店のトップまたはFCの代表として独立できる
              </li>
              <li>
                独立後もRobecityが集客した仕事の割当、学びや事例の共有がある
              </li>
            </ul>
          </div>
        </div>
        <div class="item-box space-top-150">
          <div class="left">
            <h2>Suitable for<br />people <br class="tb-only">like this</h2>
            <p class="mincyo sub">こんな人におすすめ</p>
            <img src="@/assets/Robecity-deshi-002.jpg" alt="手掛けた物件2" />
          </div>
          <div class="right">
            <ul class="top-list">
              <li>
                建築デザイナーとしての独立願望はあるが、できない人、自信がない人
              </li>
              <li>
                安田から建築デザインを学び、その後もRobecityとして一緒に仕事をしていきたい人
              </li>
              <li>独立願望はあるが、営業と仕事の獲得に自信がない人</li>
            </ul>
            <p class="block">
              Robecityは、建築デザインはもちろんのこと、集客に一切困っていない建築デザイン事務所です。それは、代表の安田が創業当初から、建築デザインへの追求だけでなく、経営者として、マーケティング・集客も夜な夜な学び、研究し、日々実行してきたからです。
            </p>
            <p class="block">
              本人いわく、建築デザインと同じくらい、マーケティングと集客に注力してきて、本当に大変であり、それがあったおかげで今のRobecityがあるとのことです。
            </p>
            <p class="block">
              このように、建築デザイナーとして独立して、やっていくためには、建築デザインの技術だけでは足りず、マーケティング・集客の両方があって成り立つものです。この点こそ、建築デザイナーとして独立を目指す人にとっての高いハードルとなります。独立後にそのことを痛感し、そのときにはもうどうしようもない、ということもあります。
            </p>
            <p class="block">
              安田は早くからこのことに気づき、集客・マーケティングに注力してきました。そして、これがRobecityの強みになっています。
            </p>
            <p class="block">
              おかげで、日本中から毎日のようにお仕事の依頼が届いています。Instagramのフォロワー数は7.6万人を超え、そこからの集客だけでも、すでに仕事が手一杯で、多くの仕事をお断りしなければいけない状況となっています。集客・マーケティングにおいて、まだまだ他にもできる施策、やりたい施策がありながら、このような状況のため、抑えている状況です。
            </p>
            <p class="block">
              安田の弟子としての独立後は、仕事の集客はRobecityが行い、全国の各エリアの支店およびFCに仕事を割り振る仕組みを想定しています。Robecityと一緒に事業をすると、マーケティングや集客の悩みから開放されます。こんな条件を提示している建築デザイン事務所は他になかなかないと思います。そのため、建築デザイナーとして独立したいが、「仕事の獲得」という点で不安がある人におすすめです。
            </p>
          </div>
        </div>
        <div class="item-box space-top-150">
          <div class="left">
            <h2>We look for<br />people <br class="tb-only">like this</h2>
            <p class="mincyo sub">求める人物像</p>
            <img src="@/assets/Robecity-deshi-003.jpg" alt="手掛けた物件3" />
          </div>
          <div class="right">
            <ul class="top-list">
              <li>性格的に明るくて、人と接する仕事ができる</li>
              <li>コミュニケーション力がある</li>
              <li>
                自発性と責任感があり、率先して行動でき、自分自身で学ぶ・仕事をこなすスタンスがある
              </li>
              <li>
                しっかりした性格、体育会系の人（ナヨナヨしている人は、仕事として合いません）
              </li>
              <li>人の気持ちを考えられる人、気配りができる人</li>
            </ul>
            <p>
              建築デザイナーとして独立するためには、また、安田の弟子として2年間で最大の学びを得るには、上記の性格的な特性が必要だと考えています。これは日々、安田を見ていて感じることです。これらの性格的な特性とRobecityでの学びがあれば、必ず成長でき、将来的に各エリアの支店のトップ、または、FCの代表として活躍することができると考えています。
            </p>
          </div>
        </div>
        <div class="item-box space-top-150">
          <div class="left">
            <h2>About<br />Yasuda</h2>
            <p class="mincyo sub">日々一緒に行動する<br class="tb-only">安田について</p>
            <img src="@/assets/Robecity-deshi-004.jpg" alt="安田" />
          </div>
          <div class="right">
            <p>
              本音ですが、マイペースで気分屋なところがあるなど、安田の性格を弟子となる人には理解してお付き合いをいただきたいと考えています。率直な言葉になりますが、安田の性格を理解しない人、合わない人を弟子にすることはできません。そのため、以下のような安田の性格を理解の上、「大丈夫」「自分と合いそう」という人のみ、弟子となることを検討していただければと思います。
            </p>
            <ul class="down-list">
              <li>基本的にはおおらかだが、気分屋なところがある</li>
              <li>マイペースで、自分の時間や仕事を邪魔されるのが嫌い</li>
              <li>一人の時間が好き、一人で考える思考・創造する時間が大切</li>
              <li>仕事・デザインへのこだわりが強い</li>
              <li>好奇心旺盛</li>
              <li>決断が早い</li>
              <li>典型的なO型タイプ</li>
            </ul>
          </div>
        </div>
        <div class="item-box space-top-150">
          <div class="left">
            <h2>Curriculum</h2>
            <p class="mincyo sub">弟子としての<br class="tb-only">教育カリキュラム</p>
            <img src="@/assets/Robecity-deshi-005.jpg" alt="教育イメージ" />
          </div>
          <div class="right">
            <p class="block">
              最短2年間で、各エリアのトップ、または、FC代表として独立できるスキルを習得していただきます。2年間のうち、最初の1年間は、下積みとして、●●や▲▲などを経験し、学んでいただきます。そして、次の1年間で、物件デザインを完成まで仕上げるスキルと業務対応を学び、身につけていただきます。これらについて、自発的かつ積極的に向き合っていただきます。
            </p>
            <p class="block">
              このような教育カリキュラムとなっていて、日々、安田の横に付き添い、安田のリアルな仕事を見て、学ぶことができます。また、Robecityには、安田がこれまで購入した教材やデザインの参考誌などもあり、これらによる学習もしていただくことができます。もちろん、安田への質問もできます。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "about-robecity",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.back {
  // background-color: white;
  margin-right: 161px;
  @media screen and (max-width: 1100px) {
    margin-right: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
  .inner-wrap {
    margin-left: calc(100% - 1346px);
    @media screen and (max-width: 1100px) {
      margin-left: 0;
    }
    .item-box {
      display: flex;
      border-bottom: 1px solid var(--main);
      padding-bottom: 150px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding-bottom: 80px;
      }
      .left {
        width: calc(calc(100% - 246px) * 0.5);
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        img {
          width: 40%;
          margin-top: 30px;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        h2{
          @media screen and (min-width:768px) and ( max-width:1024px){
            font-size: 34px;
          }
        }
        p{
          @media screen and (min-width:768px) and ( max-width:1024px){
            font-size: 18px;
          }
        }
      }
      .right {
        width: calc(100% - calc(calc(100% - 246px) * 0.5));
        @media screen and (max-width: 767px) {
          width: 100%;
        }
        .top-list,
        .down-list {
          color: var(--sub);
          font-size: 16px;
          li {
            margin-left: 20px;
            text-indent: -20px;
            list-style: disc;
            padding-left: 15px;
            @media screen and (max-width: 767px) {
              text-indent: 0;
              padding-left: 1px;
            }
          }
        }
        .top-list {
          margin-bottom: 30px;
        }
        .down-list {
          margin-top: 30px;
        }
        .block {
          margin-bottom: 20px;
        }
      }
    }
  }
}

</style>
