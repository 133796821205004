<template>
  <div class="ui-flow-box">
    <div class="outer">
      <div class="bar-box">
        <div class="bar-no">
          <p class="mincyo">{{ barNo }}</p>
        </div>
        <div class="bar-text">
          <p class="mincyo">{{ barText }}</p>
        </div>
      </div>
      <div class="sankaku-cover" v-show="sankaku === true">
        <div class="sankaku"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiFlowBox",
  components: {
    // UiDetailButton,
  },
  props: {
    barText: String,
    barNo: String,
    sankaku: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.outer {
  padding-bottom: 20px;
  text-align: center;
  .bar-box {
    display: flex;
    border: solid 1px var(--sub);
   
    .bar-no {
      width: 70px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: var(--sub);
        font-size: 21px;
        width: 100%;
        border-right: 1px solid var(--sub);
      }
  
    }
    .bar-text {
      width: calc(100% - 50px);
      display: flex;
      align-items: center;

      p {
        color: var(--sub);
        font-size: 18px;
        font-weight: bold;
        padding: 0 30px;
        text-align: left;
        @media screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 1.2em;
          padding-left: 10px;
        }
      }
    }
  }
}
.sankaku-cover {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .sankaku {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 25px 0 25px;
    border-color: #70378c transparent transparent transparent;
  }
}
</style>
