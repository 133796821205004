<template>
  <div class="qa wrap-center">
    <div class="item-box space-top-bottom-set">
      <div class="left">
        <h2>Q＆A</h2>
        <p class="mincyo sub">よくある質問</p>
        <img src="@/assets/Robecity-deshi-007.jpg" alt="手掛けた物件6" />
      </div>
      <div class="right">
        <UiQaQaBox
          v-for="qaBoxListData in qaBoxList"
          :key="qaBoxListData.id"
          :qestion="qaBoxListData.qestion"
          :answer="qaBoxListData.answer"
          class="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiQaQaBox from "../Ui/Qa/QaBox.vue";
export default {
  name: "about-recruit",
  components: {
    UiQaQaBox,
  },
  data() {
    return {
      qaBoxList: [
        {
          id: 1,
          qestion: "遠方からの応募は可能でしょうか？",
          answer:
            "可能です。ただし、活動場所は、岐阜がメインとなります。そのため、遠方の方は、岐阜にお住まいを借りていただくことになります。",
        },
        {
          id: 2,
          qestion:
            "Robecityとしてではなく、自身で独立したいですが、可能でしょうか？",
          answer:
            "前提として、Robecityとして独立していただくための「弟子制度」です。そのため、自身での独立を希望される方は応募不可です。Robecityのビジョンに共感いただけて、パートナーとして活動してくださる人のみ応募可能です。",
        },
        {
          id: 3,
          qestion: "休みをとることは可能でしょうか？",
          answer:
            "可能です。もちろん安田も休みを取ります。安田にも家族・友人がいて、趣味もあります。しかし、経営者たるものは、プライベートより仕事優先という姿勢が必須です。そのため、「弟子としての活動が優先」「その中で、計画的に休みを取る」というスタンスの方のみ応募可能となっています。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.item-box {
  display: flex;
  justify-content: space-between;
  // border-bottom: 1px solid var(--main);
  padding-bottom: 150px;
  @media screen and (max-width: 767px){
    flex-direction: column;
  }
  .left {
    width: 24%;
    @media screen and (max-width: 767px){
      width: 100%;
    }

    p {
      margin-top: 15px;
    }
    img {
      width: 80%;
      margin-top: 30px;
      @media screen and (max-width: 767px){
        width: 100%;
      }
    }
    h2{
      @media screen and (min-width:768px) and ( max-width:1024px){
        font-size: 34px;
      }
    }
  }
  .right {
    width: 67%;
    // width: calc(100% - calc(calc(100% - 246px) * 0.5));
    @media screen and (max-width: 767px){
      width: 100%;
    }
  }
}
.space-top-bottom-set{
  @media screen and (max-width: 767px){
    padding-bottom: 80px;
  }
}
</style>
