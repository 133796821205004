<template>
  <div class="about-recruit">
    <p class="title">Recruitment of<br>disciple</p>
    <h1 class="mincyo sub">安田の弟子募集について</h1>
    <p class="text space-top-50">
      Robecityの代表デザイナー安田の弟子を募集しています。日々、安田の横に付き添い、安田の仕事のすべてを見て、体験して、学ぶことができます。そして、2年間の修行の終了後は、Robecityの全国の各エリアの支店のトップ、または、FCの代表として独立し、一緒にRobecityの全国展開をしていただきます。Robecityのデザインに憧れ、Robecityとともに活動・事業をしていきたい人は、ぜひ当ページをチェックしてみてください。
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "about-recruit",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: 70px;
  line-height: 1.2;
  font-family: "YuMincho", /* Mac用 */ "Yu Mincho", /* Windows用 */ serif;
  font-weight: bold;
  @media screen and (max-width: 767px) {
  font-size: 50px;
  }
}

</style>
