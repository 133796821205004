<template>
  <div class="top-button" ontouchstart="">
    <div
      class="scroll-button"
      :class="{ 'over-baseline2': scrollY < 200 && path === `/` }"
    >
      <img src="@/assets/Robecity-deshi scroll.png" class="scroll" />
    </div>
    <div
      class="to-top-button"
      :class="{ 'over-baseline': scrollY >= 500 }"
      v-scroll-to="{
        el: '#app',
        container: 'body',
        duration: 750,
        easing: 'ease',
        offset: 0,
      }"
    >
      <div class="top">
        <p>▲</p>
        <p>Top</p>
        <!-- <img src="@/assets/GH-syoukai-top-pagetop.png" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiToTopButton",
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      //スクロールすると実行される
      if (this.scrollTimer === null) {
        //初期値はnull
        this.scrollTimer = setTimeout(
          //ここでnullじゃなくてsetTimeoutになる
          function () {
            //実行するセット
            this.scrollY = window.scrollY; //ウィンドウトップからの画面距離
            clearTimeout(this.scrollTimer); //scrollTimerをリセット
            this.scrollTimer = null; //初期値に戻す
          }.bind(this), //第一引数
          200 //第二引数(ミリ秒後)←0.2秒後にはじまるっていう意味
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.to-top-button {
  display: none;
  position: fixed;
  width: 60px;
  height: auto;
  z-index: 999;
  right: 1%;
  bottom: 7%;
  cursor: pointer;
  opacity: 0.7;
  @media screen and (max-width: 767px) {
    width: 45px;
    opacity: 0.8;
    bottom: 9%;
  }

  &:hover {
    opacity: 1;
  }
}
.scroll-button {
  display: none;
  position: fixed;
  left: 3%;
  bottom: 2%;
  z-index: 999;
  width: 25px;
  animation: scroll 3s infinite;

}
.top {
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; // background-color: (--main);
}

@keyframes scroll {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
.scroll-button {
  z-index: 2000;
  color: white;
  font-size: 50px;

}

.over-baseline {
  display: initial;
  display: flex;
  justify-content: center;
  align-items: center;
}
.over-baseline2 {
  display: initial;
  display: flex;
  justify-content: center;
  align-items: center;
    @media screen and (max-width: 768px) {
    display: none;
  }
}
</style>
