<template>
  <div class="layout-header">
    <div
      class="header"
      :class="{
        'over-baseline':
          (scrollY >= 1000 && path === '/') || (scrollY >= 0 && path !== '/')
      }"
    >
      <div class="header-cover">
        <div class="header-left">
          <div class="to-top" @click="top">
            <img src="@/assets/Robecity-deshi-logo001.png" />
          </div>
        </div>

        <div class="header-right">
          <p class="contac btn bgleft" v-scroll-to="`#contact`">
            <span>弟子の応募・<br class="sp-only">お問い合わせ</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiDrawer from "@/components/Ui/Drawer.vue";
export default {
  name: "LayoutHeader",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {
      scrollY: 0,
      scrollTimer: null,
      //  documentheight:0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTimer === null) {
        // console.log(document.body.clientHeight);
        this.documentheight = document.body.clientHeight;
        this.scrollTimer = setTimeout(
          function () {
            this.scrollY = window.scrollY;
            // console.log(this.documentheight - this.scrollY);
            clearTimeout(this.scrollTimer);
            this.scrollTimer = null;
            // console.log(this.scrollY);//長さはかるコンソール
          }.bind(this),
          200
        );
      }
    },
    top() {
      if (this.$route.path !== "/") {
        //遷移する
        this.$router.push("/");
      } else {
        this.$scrollTo("#app", 500, { easing: "ease" });
        // alert('トップページ');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: var(--main);
}
.st1 {
  fill: none;
}

.header {
  // background-color:rgba(255, 255, 255, 0);
  width: 100vw;
  height: 120px;
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s;
  top: 0;
  left: 0;
  @media screen and (max-width: 767px) {
    height: 80px;
    //  background-color: rgba(255, 255, 255, 0.2);
  }
  .header-cover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 2%;
    margin-left: 2%;
    width: 96%;
    // border-bottom: var(--main) solid 1px;
    margin-bottom: 0;
  }
  .header-left {
    width: 200px;
    // margin-left: 2%;
    display: flex;
    align-items: center;
    justify-content: center;

    .to-top {
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .to-top:hover {
      opacity: 0.8;
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 2%;
    @media screen and (min-width: 768px) and (max-width: 1010px) {
      margin-right: 1%;
    }

    @media screen and (max-width: 767px) {
      width: 50%;
      justify-content: flex-end;
      padding-right: 10px;
    }
    a {
      text-decoration: none;
      color: var(--main);
    }
    .contact {
      // border: 1px solid var(--main);
      padding: 10px 20px;
      @media screen and (max-width: 767px){
        padding: 10px;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.btn {
  /*アニメーションの起点とするためrelativeを指定*/
  position: relative;
  overflow: hidden;
  /*ボタンの形状*/
  text-decoration: none;
  display: inline-block;
  border: 1px solid var(--main); /* ボーダーの色と太さ */
  padding: 10px 30px;
  text-align: center;
  outline: none;
  background-color: rgba(255, 255, 255, 0.2);
  /*アニメーションの指定*/
  transition: ease 0.2s;
        @media screen and (max-width: 767px){
        padding: 5px 10px;
        line-height: 1.2em;
      }
}

/*ボタン内spanの形状*/
.btn span {
  position: relative;
  z-index: 3; /*z-indexの数値をあげて文字を背景よりも手前に表示*/
}

.btn:hover span {
  color: var(--sub2);
}

/*== 背景が流れる（左から右） */
.bgleft:before {
  content: "";
  /*絶対配置で位置を指定*/
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  /*色や形状*/
  background-color: var(--sub3);
  width: 100%;
  height: 100%;
  /*アニメーション*/
  transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  transform: scale(0, 1);
  transform-origin: right top;
}

/*hoverした際の形状*/
.bgleft:hover:before {
  transform-origin: left top;
  transform: scale(1, 1);
}

// .over-baseline {
//   transition: 2s;
//   background-color: rgba(255, 255, 255, 0.2);
//   .header-cover {
//     border: none;
//   }
// }
</style>
