<template>
  <div class="home">
    <h2>管理画面トップ</h2>
    <LinkButtons />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LinkButtons from "@/components/Ctrl/Home/LinkButtons.vue";

export default {
  name: "Home",
  components: {
    LinkButtons,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
</style>