<template>
  <div class="flow wrap-center">
    <div class="item-box space-top-bottom-set">
      <div class="left">
        <h2>Flow</h2>
        <p class="mincyo sub">選考から活動開始までの流れ</p>
        <img src="@/assets/Robecity-deshi-008.jpg" alt="手掛けた物件6" />
      </div>
      <div class="right">
        <UiFlowBox
          v-for="flowBoxListData in flowBoxList"
          :key="flowBoxListData.id"
          :barNo="flowBoxListData.barNo"
          :barText="flowBoxListData.barText"
          :sankaku="flowBoxListData.sankaku"
          class="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiFlowBox from "../Ui/FlowBox.vue";
export default {
  name: "about-recruit",
  components: {
    UiFlowBox,
  },
  data() {
    return {
      flowBoxList: [
        {
          id: 1,
          barNo: "01",
          barText:
            "当ページのお問い合わせフォームに、必要事項を記入の上、お問い合わせをお願いします。",
          sankaku: true,
        },
        {
          id: 2,
          barNo: "02",
          barText: "面接1回目の日時調整を行います。",
          sankaku: true,
        },
        {
          id: 3,
          barNo: "03",
          barText: "面接1回目を現地（岐阜）、または、オンラインで行います。",
          sankaku: true,
        },
        {
          id: 4,
          barNo: "04",
          barText:
            "面接1回目の合否をお伝えします。合格者には、面接2回目の日時調整を行います。",
          sankaku: true,
        },
        {
          id: 5,
          barNo: "05",
          barText:
            "面接2回目を現地（岐阜）で行います。その際、実技テストと、安田および管理職メンバーとの食事会も行います。",
          sankaku: true,
        },
        {
          id: 6,
          barNo: "06",
          barText:
            "面接2回目の合否をお伝えします。合格者には、1週間のテスト勤務の案内をします。",
          sankaku: true,
        },
        {
          id: 7,
          barNo: "07",
          barText:
            "1週間のテスト勤務の終了後、Robecityでの活動が開始となります。",
          sankaku: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.item-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 150px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .left {
    width: 24%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    p {
      margin-top: 15px;
    }
    img {
      width: 80%;
      margin-top: 30px;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
        h2{
      @media screen and (min-width:768px) and ( max-width:1024px){
        font-size: 34px;
      }
    }
  }
  .right {
    width: 67%;
    // width: calc(100% - calc(calc(100% - 246px) * 0.5));
    @media screen and (max-width: 767px){
      width: 100%;
      margin-top: 50px;
    }
  }
}
.space-top-bottom-set{
  @media screen and (max-width: 767px){
    padding-bottom: 80px;
  }
}
</style>
