<template>
  <div class="about-robecity">
    <div class="whiteback space-top-bottom-set">
      <div class="wrap-center">
        <h2>About<br />Robecity</h2>
        <p class="mincyo sub">Robecityについて</p>
        <p class="text space-top-50">
          Robecity代表の安田がこれまで積み上げてきた実績について紹介します。ご覧いただければ、<br />稀有な建築デザイン事務所であることがおわかりいただけるかと思います。
        </p>
        <h3 class="mincyo sub-vioret">
          他にはない、街並にインパクトを与える物件デザイン
        </h3>
      </div>

      <UiSliderBox
        :images="images"
        :swiperOption="swiperOption"
        :height="100"
        :width="99"
        :button="false"
        class="slider"
      />
      <div class="wrap-center space-top-100">
        <h3 class="mincyo sub-vioret">手掛けた物件は200件超</h3>
        <img src="@/assets/Robecity-deshi-002.png" alt="手掛けた物件数pc" class="pc-only"/>
        <img src="@/assets/Robecity-deshi-002SP.png" alt="手掛けた物件数sp"  class="sp-only"/>
        <h3 class="mincyo sub-vioret space-top-150">
          Instagramフォロワー{{ currentFollowerData.total }}万人超
        </h3>
        <div class="insta-cover">
          <img
            src="@/assets/Robecity-deshi-009.jpg"
            alt="Instagramフォロワー"
            class="insta pc-only"
          />
          <img
            src="@/assets/Robecity-deshi-009SP.jpg"
            alt="Instagramフォロワー"
            class="insta sp-only"
          />
        </div>
        <p class="note">
          Robecity Instagram フォロワー数{{ currentFollowerData.robecity }}万人
        </p>
        <p class="note">
          安田隆一 Instagram フォロワー数{{ currentFollowerData.yasuda }}万人
        </p>
      </div>

      <div class="wrap-center space-top-150 tv-sp">
        <h3 class="mincyo sub-vioret">テレビ出演実績多数</h3>
        <div class="tv-box">
          <div class="block">
            <div class="left">
              <img
                src="@/assets/tokyo-mx.png"
                alt="TOKYO MXロゴ"
                class="tokyomx"
              />
              <p>
                成長中の企業経営者や注目のドクターの方々をメインナビゲーターのトリンドル玲奈さんが対談形式で紹介していくTOKYO
                MXの特別番組
                「ROUTINE」に、弊社代表安田が出演し、地上波TVでトリンドル玲奈さんと対談いたしました。
              </p>
            </div>
            <div class="right">
              <img src="@/assets/Robecity-deshi-tv-001.jpg" alt="TOKYO MX1" />
              <img src="@/assets/Robecity-deshi-tv-002.jpg" alt="TOKYO MX2" />
            </div>
          </div>
          <div class="block">
            <div class="left">
              <img
                src="@/assets/zero1-logo.png"
                alt="ゼロイチロゴ"
                class="zeroichi"
              />
              <p>
                指原莉乃さんが司会を務める日本テレビの「ゼロイチ」に、Robecityがデザインした住宅が特集されました。
              </p>
            </div>
            <div class="right">
              <img src="@/assets/Robecity-deshi-tv-003.jpg" alt="ゼロイチ1" />
              <img src="@/assets/Robecity-deshi-tv-004.jpg" alt="ゼロイチ2" />
            </div>
          </div>
          <div class="block">
            <div class="left">
              <img
                src="@/assets/ariyoshizemi.jpg"
                alt="有吉ゼミロゴ"
                class="ariyoshi"
              />
              <p>
                有吉弘行さんが司会を務める日本テレビの「有吉ゼミ」に、Robecityがデザインした住宅が特集されました。
              </p>
            </div>
            <div class="right">
              <img src="@/assets/Robecity-deshi-tv-005.jpg" alt="有吉ゼミ1" />
              <img src="@/assets/Robecity-deshi-tv-006.jpg" alt="有吉ゼミ2" />
            </div>
          </div>
        </div>
        <p class="space-top-50">
          ここで挙げた実績は一例ですが、著名人の物件デザインなど、他にも数々の実績があります。Robecity・安田は、自分自身で独立・開業した建築デザイナーとしては、日本でも有数の実績を誇っています。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "about-robecity",
  components: { UiSliderBox },
  data() {
    return {
      images: [
        { id: 1, imgpath: "example_01.webp", name: "扶養の範囲内で働きたい" },
        {
          id: 2,
          imgpath: "example_02.webp",
          name: "空いてる時間での時短勤務を希望",
        },
        {
          id: 3,
          imgpath: "example_03.webp",
          name: "勤務曜日と勤務時間の自由がほしい",
        },
        {
          id: 4,
          imgpath: "example_004.webp",
          name: "家庭・家族・プライベートが優先",
        },
        {
          id: 5,
          imgpath: "example_005.webp",
          name: "子供の体調不良のときに急な休みが取れる",
        },
        {
          id: 6,
          imgpath: "example_06.webp",
          name: "会社での無駄な拘束時間＜家庭・家族との時間",
        },
        { id: 7, imgpath: "example_07.webp", name: "デスクワークがしたい" },
      ],
      swiperOption: {
        slidesPerView: 2.0,
        spaceBetween: 30, //各スライドの余白
        speed: 750, //スライドの切り替わりスピード
        effect: "slide",
        centeredSlides: true, //スライダーを真ん中に
        loop: true, //無限ループ
        autoplay: {
          delay: 2500, //スライドの自動切り替えの秒数
          disableOnInteraction: false, //何らかのアクション後の自動切り替えを再開
        },
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        //ナビゲーション設定
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1200: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
      currentFollowerData: {
        robecity: 0,
        yasuda: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {
    if (window.matchMedia("(min-width: 1150px)").matches) {
      // default
      // alert(1);
    } else if (window.matchMedia("(min-width: 767px)").matches) {
      // tablet
      // alert(2);
      this.swiperOption.slidesPerView = "3";
      this.swiperOption.spaceBetween = 20;
    } else {
      // sp
      // alert(0);
      this.swiperOption.direction = "horizontal";
      this.swiperOption.slidesPerView = "1.3";
      this.swiperOption.speed = 800;
      this.swiperOption.spaceBetween = 20;
    }

    //
    this.getFollowerData();
  },
  methods: {
    getFollowerData() {
      this.$axios
        .get(`${this.rootUrl}/api/get-follower-data.php`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.currentFollowerData = response.data.currentFollowerData;
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.whiteback {
  background-color: white;
  margin-right: 161px;
  @media screen and(max-width: 1100px) {
    margin-right: 0;
  }
}
.wrap-center {
  margin-left: calc(calc(calc(100% - 1261px) * 0.5)+164px);
  margin-right: calc(calc(100% - 1261px) * 0.5);
  @media screen and(max-width: 1100px) {
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.insta-cover {
  display: flex;
  align-items: center;
  justify-content: center;
  .insta {
    width: 80%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
.note {
  text-align: right;
}
.tv-sp {
  @media screen and (max-width: 767px) {
    margin-top: 80px;
  }
}
.tv-box {
  border-top: 1px solid var(--main);
  border-bottom: 1px solid var(--main);
  padding: 40px 0 80px;
  @media screen and (max-width: 767px) {
    padding: 0 0 80px;
  }
  .block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 60px;
    }

    .left {
      width: 45%;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 20px;
      }
      .tokyomx {
        width: 250px;
        @media screen and (max-width: 767px) {
          margin-bottom: 10px;
        }
      }
      .zeroichi {
        width: 150px;
        @media screen and (max-width: 767px) {
          margin-bottom: 10px;
        }
      }
      .ariyoshi {
        width: 100px;
        @media screen and (max-width: 767px) {
          margin-bottom: 10px;
        }
      }
    }
    .right {
      width: 50%;
      display: flex;
      justify-content: space-between;
      img {
        width: 48%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
</style>
