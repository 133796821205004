<template>
  <div id="app">
    <Header class="header" :path="path" />
    <router-view class="body-top" />
    <ToTopButton :path="path" />
    <Footer :path="path" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  color: rgb(125, 125, 130);
  line-height: 2em;
  @media screen and (max-width: 767px) {
    line-height: 1.6em;
  }
}

:root {
  --main: rgb(125, 125, 130);
  --sub: rgb(90, 90, 97);
  --sub2: rgb(237, 237, 240);
  --sub3: rgb(112, 55, 144);
  --black: rgb(125, 125, 130);
}

html * {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

.wrap-center {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1100px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.wrap-right,
.wrap-left {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1500px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定

  @media screen and (max-width: 1100px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.sub-vioret {
  font-size: 28px;
  color: var(--sub3);
  line-height: 1.2;
  padding-top: 20px;
  padding-bottom: 50px;
}

.wrap-center {
  margin: 0 auto;
}
.wrap-right {
  margin-left: calc(calc(100% - 1100px) * 0.5);
  margin-right: 0;
  @media screen and (max-width: 1100px){
    margin: 0 auto;
  }
}
.wrap-left {
  margin-right: calc(calc(100% - 1100px) * 0.5);
    @media screen and (max-width: 1100px){
    margin: 0 auto;
  }
}

.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.tb-only {
  display: none;
  @media screen and (min-width:768px) and ( max-width:1024px) {
    display: initial !important;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}


.body-top {
  padding-top: 120px;
  background-color: var(--sub2);
  @media screen and (max-width: 767px) {
    padding-top: 80px;
  }
}
h1,
h2 {
  font-size: 70px;
  line-height: 1.2;
  font-family: "YuMincho", /* Mac用 */ "Yu Mincho", /* Windows用 */ serif;
  font-weight: bold;
  @media screen and (max-width: 767px) {
  font-size: 50px;
  }
}
.mincyo {
  font-family: "YuMincho", /* Mac用 */ "Yu Mincho", /* Windows用 */ serif;
}

.sub {
  font-size: 20px;
}
//ヘッダー用のスペースレンジャー
.space-top-152 {
  padding-top: 152px;
  @media screen and (max-width: 767px) {
    padding-top: 80px;
  }
}
.space-top-48 {
  margin-top: 48px;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }
}
//共通スペースレンジャー
.space-top-bottom-set {
  padding-top: 150px;
  padding-bottom: 150px;
    @media screen and (max-width: 767px) {
  padding-top: 80px;
  padding-bottom: 80px;
  }
}
.space-top-20 {
  margin-top: 20px;
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
}
.space-top-50 {
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
}
.space-top-100 {
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    margin-top: 50px;
  }
}
.space-top-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 80px;
  }
}
.space-top-300 {
  margin-top: 300px;
  @media screen and (max-width: 767px) {
    margin-top: 150px;
  }
}
.space-btm-200 {
  margin-bottom: 200px;
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.space-btm-200p {
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-bottom: 100px;
  }
}
</style>
