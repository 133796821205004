<template>
  <div class="link-buttons">
    <router-link :to="{ name: 'ctrl-followers' }">
      <div class="link-button">フォロワー</div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LinkButtons",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.link-buttons {
  padding: 30px;
  display: flex;
  a {
    text-decoration: none;
    color: inherit;
    .link-button {
      border: solid 1px #aaa;
      border-radius: 7px;
      font-size: 24px;
      padding: 20px 50px;
      cursor: pointer;
    }
  }
}
</style>
