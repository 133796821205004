<template>
  <div class="top" id="contact">
    <div class="cover wrap-center space-top-bottom-set">
      <p class="sub-vioret mincyo">安田の弟子の<br class="sp-only">応募・お問い合わせ</p>

<p class="hissu">全項目入力必須</p>
      <HomeContactContactForm/>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeContactContactForm from "@/components/Home/Contact/ContactForm.vue";
export default {
  name: "top",
  components: {HomeContactContactForm},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },

};
</script>

<style lang="scss" scoped>
.cover{
  // padding-bottom:100px;
}
.sub-vioret{
font-size: 40px;
text-align: center;
@media screen and (max-width: 767px){
  font-size: 32px;
}
}
.hissu{
  color: var(--sub3);
  font-size: 18px;
}
</style>
