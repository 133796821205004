<template>
  <div class="editor">
    <div class="current">
      <h3>現在</h3>
      <p>
        Robecity Instagram フォロワー数 {{ currentFollowerData.robecity }} 万人
      </p>
      <p>
        安田隆一 Instagram フォロワー数 {{ currentFollowerData.yasuda }} 万人
      </p>
      <p>
        （計
        {{ currentFollowerData.total }} 万人）
      </p>
    </div>
    <div class="new">
      <h3>新規</h3>
      <p>
        Robecity Instagram フォロワー数
        <input type="number" v-model="newFollowerData.robecity" step="0.1" />
        万人
      </p>
      <p>
        安田隆一 Instagram フォロワー数
        <input type="number" v-model="newFollowerData.yasuda" step="0.1" /> 万人
      </p>
      <!-- <p>
        （計
        {{
          parseFloat(newFollowerData.robecity) +
          parseFloat(newFollowerData.yasuda)
        }}
        万人）
      </p> -->
      <button @click="registerFollowerData">更新</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Editor",
  components: {},
  data() {
    return {
      currentFollowerData: {
        robecity: 0,
        yasuda: 0,
      },
      newFollowerData: {
        robecity: 0,
        yasuda: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {
    this.getFollowerData();
  },
  methods: {
    getFollowerData() {
      this.$axios
        .get(`${this.rootUrl}/api/get-follower-data.php`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.currentFollowerData = response.data.currentFollowerData;
            this.newFollowerData = JSON.parse(
              JSON.stringify(response.data.currentFollowerData)
            );
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    registerFollowerData() {
      // 
      if (
        confirm(
          `・robecity: ${this.newFollowerData.robecity}万人\n・安田: ${this.newFollowerData.yasuda}万人\nで登録します。よろしいですか？`
        )
      ) {
        // through.
      } else {
        return;
      }

      //
      let params = new URLSearchParams();
      params.append("new_follower_data", JSON.stringify(this.newFollowerData));
      this.$axios
        .post(`${this.rootUrl}/api/register-follower-data.php`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.getFollowerData();
            return;
          } else {
            alert("エラーが発生しました。");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  padding: 50px;
}
button {
  margin-top: 15px;
}
.new {
  input {
    width: 50px;
  }
}
</style>
